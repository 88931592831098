const executives = 
    [{
        name: "BÁRBARA",
        position: "Asesor de ventas",
        phoneFormatted: "(443)-207-5230",
        phone: "4432075230",
        email: "barbara.reyes@naranti.com.mx",
        officePhoneNumberFormatted: "(443)-338-2109",
        officePhoneNumber: "4433382109",
        gender: "F"
    }, {
        name: "KARINA",
        position: "Asesor de ventas",
        phoneFormatted: "(999)-368-3829",
        phone: "9993683829",
        email: "karina.caballero@naranti.com.mx",
        officePhoneNumberFormatted: "(443)-338-2109",
        officePhoneNumber: "4433382109",
        gender: "F"
    }, {
        name: "KEVIN",
        position: "Asesor de ventas",
        phoneFormatted: "(443)-377-2455",
        phone: "4433772455",
        email: "kevin.rodriguez@naranti.com.mx",
        officePhoneNumberFormatted: "(443)-338-2109",
        officePhoneNumber: "4433382109",
        gender: "M"
    }, {
        name: "KAREN",
        position: "Asesor de ventas",
        phoneFormatted: "(443)-397-4552",
        phone: "4433974552",
        email: "karen.castellanos@naranti.com.mx",
        officePhoneNumberFormatted: "(443)-338-2109",
        officePhoneNumber: "4433382109",
        gender: "F"
    }, {
        name: "LUIS",
        position: "Asesor de ventas",
        phoneFormatted: "(443)-207-6488",
        phone: "4432076488",
        email: "luis.lemus@naranti.com.mx",
        officePhoneNumberFormatted: "(443)-338-2109",
        officePhoneNumber: "4433382109",
        gender: "M"
    }]
const currentExecutive = executives[Math.floor(Math.random() * executives.length)]
const initialState = {
    media: {
        map: null,
        filters: [],
        mediaList: [],
        mediaFiltered: [],
        markersList: [],
        mapCircleList: [],
        pointsOfInterest: {},
        markerClusterer: null,
        favoriteMediaList: [],
        showMediaModal: false,
        showContactModal: false,
        currentMedium: null,
        executives: executives,
        currentExecutive: currentExecutive,
        loading: true,
        country: null,
        viewOnlyFavoriteList: false
    },
}

export default initialState;